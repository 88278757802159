import * as React from "react";
import { t } from "i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { NavIconContainer, NavIcon } from "../lib/navicon";
import { ReactComponent as Help } from "../../assets/images/question.svg";
import PendoTrack from "../Pendo/Pendo";
import { useImportScript } from "../Utils";
import { useContext, useEffect, useState } from "react";
import { AuthCookieContext } from "../AuthCookieContext";
import { openLiveChat } from "../Utils";

export function NavbarHelp({
  rgpTenantId,
}: {
  rgpTenantId: string | undefined;
}) {
  useImportScript(process.env.REACT_APP_LIVE_CHAT || "");
  const helpUrl = t("HELP_URL");
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [customerCenterUrl, setCustomerCenterUrl] = useState<
    string | undefined
  >();
  const authCookieContext = useContext(AuthCookieContext);

  useEffect(() => {
    if (authCookieContext?.customercenterurl)
      setCustomerCenterUrl(authCookieContext.customercenterurl);
  }, []);

  const handleOpenHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const HelpMenuItem = ({
    title,
    displayText,
    onClick,
  }: {
    title: string;
    displayText: string;
    onClick: () => void;
  }) => (
    <MenuItem
      title={title}
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <Typography textAlign="center">{displayText}</Typography>
    </MenuItem>
  );

  const handleCloseHelpMenu = () => {
    setAnchorElUser(null);
  };

  const selectMenuItem = (type: string, url: string) => {
    setAnchorElUser(null);
    switch (type) {
      case "help":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Help Link",
          },
        });
        window.open(url, "_blank");
        break;
      case "chat-support":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Chat With Support",
          },
        });
        openLiveChat(rgpTenantId);
        break;
      case "customer-center":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Customer Center",
          },
        });
        window.open(url, "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavIconContainer
        data-testid="help-icon"
        title="Open Help Menu"
        onClick={handleOpenHelpMenu}
      >
        <NavIcon>
          <Help />
        </NavIcon>
      </NavIconContainer>
      <Menu
        disableAutoFocusItem
        sx={{ mt: "45px" }}
        id="help-menu"
        data-testid="help-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseHelpMenu}
      >
        {[
          <HelpMenuItem
            key="help"
            title="Go to Help Documentation"
            displayText="Help"
            onClick={() => selectMenuItem("help", helpUrl)}
          />,
          rgpTenantId && (
            <HelpMenuItem
              key="chat-support"
              title="Chat with Support"
              displayText="Chat with Support"
              onClick={() => selectMenuItem("chat-support", "")}
            />
          ),
          customerCenterUrl && (
            <HelpMenuItem
              key="customer-center"
              title="Go to Customer Center"
              displayText="Customer Center"
              onClick={() =>
                selectMenuItem("customer-center", customerCenterUrl)
              }
            />
          ),
        ]}
      </Menu>
    </>
  );
}
