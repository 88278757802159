import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { NavUserIconContainer } from "../lib/navicon";
import { ReactComponent as User } from "../../assets/images/user.svg";
import PendoTrack from "../Pendo/Pendo";
import { doLogout, getRgpBaseUri } from "../Utils";
import { useContext, useEffect, useState } from "react";
import { AuthCookieContext } from "../AuthCookieContext";
import { UserContext } from "../UserContext";

export function NavbarUserSettings({
  name,
  isMultiCustomerUser,
  isAdmin,
}: {
  name: string | undefined;
  isMultiCustomerUser: boolean;
  isAdmin: boolean;
}) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [rgpBaseUri, setRgpBaseUri] = useState<string | undefined>();
  const authCookieContext = useContext(AuthCookieContext);
  const userContext = React.useContext(UserContext);
  const isUKCustomer = userContext?.userInfo?.countryCode === "GB";

  useEffect(() => {
    if (authCookieContext?.homeuri)
      setRgpBaseUri(getRgpBaseUri(authCookieContext.homeuri));
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const RGPMenuItem = ({
    title,
    path,
    displayText,
    onClick,
  }: {
    title: string;
    path: string;
    displayText: string;
    onClick: () => void;
  }) => (
    <MenuItem
      title={title}
      component="a"
      href={`${rgpBaseUri}${path}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <Typography textAlign="center">{displayText}</Typography>
    </MenuItem>
  );

  const handleCloseUserMenu = (type: string) => {
    setAnchorElUser(null);
    switch (type) {
      case "settings":
        navigate(!isUKCustomer ? "/settings/profile" : "/settings/visibility"); // hide School Leader Visibility tab for UK customers
        // we don't use a custom pendo track here because we already capture the page change
        break;
      case "switchAccounts":
        if (window.location.href.includes("schoolzilla.com")) {
          window.location.href = `${process.env.REACT_APP_SZ_AUTH_SERVICE}/select-customer`;
        } else {
          window.location.href = `${process.env.REACT_APP_REN_AUTH_SERVICE}/select-customer`;
        }
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Switch Accounts",
          },
        });
        break;
      case "logout":
        doLogout();
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Logout",
          },
        });
        break;
      case "user-profile":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Manage user profile",
          },
        });
        break;
      case "manage-apps-users":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Manage apps & users",
          },
        });
        break;
      case "locked-students":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Locked students",
          },
        });
        break;
      case "preference-portal":
        PendoTrack({
          eventType: "mosaic.click.nav.select",
          eventData: {
            label: "Preference portal",
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavUserIconContainer
        onClick={handleOpenUserMenu}
        data-testid="navbar-user-settings"
        title="User Settings"
      >
        <User />
      </NavUserIconContainer>
      <Menu
        disableAutoFocusItem
        sx={{ mt: "45px" }}
        id="user-settings-menu"
        data-testid="menu-user-settings"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {rgpBaseUri && [
          <RGPMenuItem
            key="edit-profile"
            title="Manage your account's profile"
            path="/userprofileportal/"
            displayText="Edit Profile"
            onClick={() => handleCloseUserMenu("user-profile")}
          />,
          <RGPMenuItem
            key="manage-apps-users"
            title="Manage your apps and users"
            path="/sismanagement/home"
            displayText="Manage Apps & Users"
            onClick={() => handleCloseUserMenu("manage-apps-users")}
          />,
          <RGPMenuItem
            key="locked-students"
            title="Manage students who have been locked"
            path="/sismanagement/users/unlock-my-students"
            displayText="Locked Students"
            onClick={() => handleCloseUserMenu("locked-students")}
          />,
          <RGPMenuItem
            key="edit-preferences"
            title="Manage various product preferences"
            path="/preferenceportal/#/"
            displayText="Edit Preferences"
            onClick={() => handleCloseUserMenu("preference-portal")}
          />,
        ]}

        {isAdmin && (
          <MenuItem
            onClick={() => handleCloseUserMenu("settings")}
            title="Go to Renaissance Analytics Settings"
          >
            <Typography textAlign="center">
              Renaissance Analytics Settings
            </Typography>
          </MenuItem>
        )}
        {isMultiCustomerUser && (
          <MenuItem
            onClick={() => handleCloseUserMenu("switchAccounts")}
            title="Switch Renaissance accounts"
            id="switch-accounts-button"
          >
            <Typography textAlign="center">Switch Accounts</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleCloseUserMenu("logout")}
          title="Log out of Renaissance"
        >
          <Typography textAlign="center">Log Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
