import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { asyncWithLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import { AuthCookieContext } from "./components/AuthCookieContext";
import { UserInfoJson, School } from "./interfaces/UserInfoJson";
import { UserSettingsJson } from "./interfaces/UserSettingsJson";
import { NavbarGlobal } from "./components/NavbarGlobal";
import { PageBackground } from "./AppStyles";
import { PageErrorBoundary } from "./components/Error/PageErrorBoundary";
import { PageRenderError } from "./components/Error/PageRenderError";
import { UserContext } from "./components/UserContext";
import { Entitlement } from "./components/Waffle/ConvertEntitlements";
import { LeftNavWrapper } from "./components/LeftNav/LeftNavWrapper";
import { Spinner } from "./components/Spinner";

/* we use webpack preload to load these important pages/components in parallel to the parent chunk */
const Home = lazy(() => import(/* webpackPreload: true */ "./Home"));
const DistrictProfile = lazy(
  () => import(/* webpackPreload: true */ "./DistrictProfile")
);
const SchoolProfile = lazy(
  () => import(/* webpackPreload: true */ "./SchoolProfile")
);
const OurStudents = lazy(
  () => import(/* webpackPreload: true */ "./OurStudents")
);
const OurSchools = lazy(
  () => import(/* webpackPreload: true */ "./OurSchools")
);
const StudentProfile = lazy(
  () => import(/* webpackPreload: true */ "./StudentProfile")
);
const NavbarSubnav = lazy(
  () =>
    import(/* webpackPreload: true */ "./components/NavbarSubnav/NavbarSubnav")
);

/* we use webpack prefetch to load these pages as soon as the above have been loaded in, as these pages are less commonly used */
const DemographicConfiguration = lazy(
  () => import(/* webpackPrefetch: true */ "./DemographicConfiguration")
);
const VisibilitySettings = lazy(
  () => import(/* webpackPrefetch: true */ "./VisibilitySettings")
);
const Settings = lazy(() => import(/* webpackPrefetch: true */ "./Settings"));
const ProfileVisibilitySettings = lazy(
  () => import(/* webpackPrefetch: true */ "./ProfileVisibilitySettings")
);
const SchoolConfiguration = lazy(
  () => import(/* webpackPrefetch: true */ "./SchoolConfiguration")
);

interface AppRouterProps {
  userInfo: UserInfoJson | null;
  userSettings: UserSettingsJson | null;
  schools: School[] | null;
  isEmptyCustomer: boolean;
}

const useLeftNav = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  // same as set by teacher journey
  return width > 1024;
};

function AppRouter({
  userInfo,
  userSettings,
  schools,
  isEmptyCustomer,
}: AppRouterProps) {
  const authCookieContext = React.useContext(AuthCookieContext);
  const [isLeftNavExpanded, setIsLeftNavExpanded] = useState(true);
  const location = useLocation();
  const { frontendLeaderJourneyHomepage, frontendLeftNav } = useFlags();

  // We use this in the Home component. Where we only show schools to users who can't see the whole district
  const showSchools =
    userInfo &&
    userInfo.role !== "admin" &&
    userInfo.role !== "district_leader";

  // base whether we use the left nav off of the size of the screen and the feature flag (for now)
  const isUseLeftNav = useLeftNav() && frontendLeftNav;
  // use an effect to set the left nav to collapsed if the screen is too small
  useEffect(() => {
    if (!isUseLeftNav) {
      setIsLeftNavExpanded(false);
    }
  }, [isUseLeftNav]);

  // left nav is expanded by default on the home page, collapsed on the other pages
  useEffect(() => {
    if (location.pathname === "/") {
      setIsLeftNavExpanded(true);
    } else {
      setIsLeftNavExpanded(false);
    }
  }, [location]);

  return (
    <UserContext.Provider value={{ userInfo, userSettings }}>
      <NavbarGlobal
        name={userInfo?.customer_name}
        rgpTenantId={authCookieContext?.tenant_id}
        isMultiCustomerUser={Boolean(
          userInfo?.user_customers && userInfo.user_customers.length > 1
        )}
        isAdmin={userInfo?.role === "admin"}
      ></NavbarGlobal>

      <PageBackground>
        {isUseLeftNav && (
          <LeftNavWrapper
            isLeftNavExpanded={isLeftNavExpanded}
            setIsLeftNavExpanded={setIsLeftNavExpanded}
          />
        )}
        <div>
          <NavbarSubnav
            role={userInfo?.role}
            isEmptyCustomer={isEmptyCustomer}
          />

          <main style={{ width: "100%" }}>
            <Suspense fallback={<Spinner />}>
              {/* Should handle any errors that stop the page from rendering completely. */}
              <PageErrorBoundary
                fallback={<PageRenderError />}
                location={location}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      isEmptyCustomer ||
                      frontendLeaderJourneyHomepage === "showLeaderJourney" ? (
                        <Home
                          firstName={userInfo?.firstName}
                          schools={showSchools ? userInfo?.schools : null}
                          isEmptyCustomer={isEmptyCustomer}
                        />
                      ) : userInfo?.role === "school_leader" ? (
                        schools && <SchoolProfile schools={schools} />
                      ) : (
                        <DistrictProfile
                          customerName={userInfo?.customer_name}
                        />
                      )
                    }
                  />
                  <Route
                    path="/profile/district"
                    element={
                      <DistrictProfile customerName={userInfo?.customer_name} />
                    }
                  />
                  <Route
                    path="/profile/school"
                    element={schools && <SchoolProfile schools={schools} />}
                  >
                    <Route
                      path=":schoolId"
                      element={schools && <SchoolProfile schools={schools} />}
                    />
                  </Route>
                  <Route
                    path="/comparison/school"
                    element={
                      <OurSchools isLeftNavExpanded={isLeftNavExpanded} />
                    }
                  />
                  <Route
                    path="/comparison/student"
                    element={
                      schools && (
                        <OurStudents
                          schools={schools}
                          isLeftNavExpanded={isLeftNavExpanded}
                          role={userInfo?.role}
                        />
                      )
                    }
                  />

                  <Route path="/settings">
                    <Route
                      path="/settings/visibility"
                      element={
                        <Settings
                          selected="visibility"
                          isLeftNavExpanded={isLeftNavExpanded}
                        >
                          <VisibilitySettings />
                        </Settings>
                      }
                    />
                    <Route
                      path="/settings/profile"
                      element={
                        <Settings
                          selected="profile"
                          isLeftNavExpanded={isLeftNavExpanded}
                        >
                          <ProfileVisibilitySettings />
                        </Settings>
                      }
                    />
                    <Route
                      path="/settings/demographic"
                      element={
                        <Settings
                          selected="demographics"
                          isLeftNavExpanded={isLeftNavExpanded}
                        >
                          <DemographicConfiguration />
                        </Settings>
                      }
                    >
                      <Route
                        path=":groupingTemplateId"
                        element={
                          <Settings
                            selected="demographics"
                            isLeftNavExpanded={isLeftNavExpanded}
                          >
                            <DemographicConfiguration />
                          </Settings>
                        }
                      ></Route>
                    </Route>
                  </Route>

                  <Route
                    path="/settings/school"
                    element={
                      <Settings
                        selected="school"
                        isLeftNavExpanded={isLeftNavExpanded}
                      >
                        <SchoolConfiguration />
                      </Settings>
                    }
                  >
                    <Route
                      path=":groupingTemplateId"
                      element={
                        <Settings
                          selected="school"
                          isLeftNavExpanded={isLeftNavExpanded}
                        >
                          <SchoolConfiguration />
                        </Settings>
                      }
                    ></Route>
                  </Route>

                  {/* /data-configuration/... is the URL from the angular app, redirect to new URL */}
                  <Route
                    path="/data-configuration/settings/demographic"
                    element={<Navigate to="/settings/demographic" />}
                  >
                    <Route
                      path=":groupingTemplateId"
                      element={
                        <Navigate to="/settings/demographic/:groupingTemplateId" />
                      }
                    />
                  </Route>

                  <Route
                    path="/data-configuration/settings/schools"
                    element={<Navigate to="/settings/school" />}
                  >
                    <Route
                      path=":groupingTemplateId"
                      element={
                        <Navigate to="/settings/school/:groupingTemplateId" />
                      }
                    />
                  </Route>

                  <Route
                    path="/profile/student"
                    element={
                      schools && (
                        <StudentProfile
                          schools={schools}
                          isLeftNavExpanded={isLeftNavExpanded}
                        />
                      )
                    }
                  >
                    <Route
                      path=":studentId"
                      element={
                        schools && (
                          <StudentProfile
                            schools={schools}
                            isLeftNavExpanded={isLeftNavExpanded}
                          />
                        )
                      }
                    />
                  </Route>
                </Routes>
              </PageErrorBoundary>
            </Suspense>
          </main>
        </div>
      </PageBackground>
    </UserContext.Provider>
  );
}

export default AppRouter;
