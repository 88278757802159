import React from "react";
import { ReactComponent as Home } from "../assets/images/home.svg";
import { NavIconContainer, NavIcon } from "./lib/navicon";
import PendoTrack from "./Pendo/Pendo";
import { AuthCookieContext } from "./AuthCookieContext";

export const RGPHome = () => {
  const authCookieContext = React.useContext(AuthCookieContext);

  const navigateToRGPHome = () => {
    PendoTrack({
      eventType: "mosaic.click.nav.select",
      eventData: {
        label: "RGP Home",
      },
    });

    window.location.href = authCookieContext?.homeuri
      ? authCookieContext.homeuri
      : process.env.REACT_APP_DEFAULT_HOME_URI!;
  };

  return (
    <NavIconContainer title="RGP Home" onClick={() => navigateToRGPHome()}>
      <NavIcon>
        <Home />
      </NavIcon>
    </NavIconContainer>
  );
};
