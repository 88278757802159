import React, { useState } from "react";
import { styled, keyframes } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import LeftNavIcon from "./LeftNavIcon";
import { AppNavItem } from "../Waffle/WaffleMenu";
import Link from "@mui/material/Link";
import { RALink } from "../lib/link";
import { ReactComponent as OpenNewTab } from "../../assets/images/open_new_tab.svg";
import { fadeInSlideUp } from "../../AppStyles";
import RenaissanceNextPane from "../RenaissanceNextPane/RenaissanceNextPane";

const StyledDiv = styled("div")`
  flex-shrink: 0;
  background-color: var(--lightgrey);
  z-index: 998;
  animation: ${fadeInSlideUp} 0.3s ease-in-out;
`;

const IconLabel = styled("span")`
  font-size: var(--std-font-md);
  margin-left: var(--std-margin-sm);
`;

const IconAndTextDiv = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: var(--darkgrey);
  &:hover .icon-label {
    font-weight: bold;
  }
`;

const SectionTitle = styled("h2")`
  color: var(--gra-7, #555);
  font-size: var(--std-font-lg);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: var(--std-margin-lg) 0 var(--std-margin-xs) 0;
  padding: 0;
  animation: ${fadeInSlideUp} 0.3s ease-in-out;
`;

const LearnMoreLink = styled(Link)`
  color: var(--gra-7, #555);
  font-size: var(--std-font-sm);
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
`;

const LearnMoreLinkContainer = styled("div")`
  margin-bottom: var(--std-margin-sm);
  animation: ${fadeInSlideUp} 0.3s ease-in-out;
`;

const AppItem = ({
  app,
  itemClicked,
}: {
  app: AppNavItem;
  itemClicked: (appNavItem: AppNavItem) => void;
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  if (
    isHovered &&
    divRef.current &&
    divRef.current.matches(":hover") === false
  ) {
    setIsHovered(false);
  }

  return (
    <IconAndTextDiv
      onClick={() => itemClicked(app)}
      ref={divRef}
      key={app.displayName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="app-icon"
    >
      <LeftNavIcon imagePrefix={app.imagePrefix} isHovered={isHovered} />
      <IconLabel className="icon-label">{app.displayName}</IconLabel>
    </IconAndTextDiv>
  );
};

export const LeftNavExpanded = ({
  availableApps,
  availableData,
  hasTeacherJourneyLaunch,
  itemClicked,
  className,
}: {
  availableApps: AppNavItem[];
  availableData: AppNavItem[] | null;
  hasTeacherJourneyLaunch: boolean;
  itemClicked: (appNavItem: AppNavItem) => void;
  className?: string;
}) => {
  const { frontendRenNextPane } = useFlags();
  return (
    <StyledDiv className={className}>
      {frontendRenNextPane ? (
        <RenaissanceNextPane
          hasTeacherJourneyLaunch={hasTeacherJourneyLaunch}
        />
      ) : null}
      <SectionTitle>My Data</SectionTitle>
      {availableData?.map((app: AppNavItem) => {
        return (
          <AppItem key={app.displayName} app={app} itemClicked={itemClicked} />
        );
      })}
      <SectionTitle>My Apps</SectionTitle>
      <LearnMoreLinkContainer>
        <LearnMoreLink
          href="https://www.renaissance.com/products/"
          target="_blank"
        >
          Learn More <OpenNewTab style={{ height: "14px", width: "14px" }} />
        </LearnMoreLink>
      </LearnMoreLinkContainer>
      {availableApps.map((app: AppNavItem) => {
        return (
          <AppItem key={app.displayName} app={app} itemClicked={itemClicked} />
        );
      })}
    </StyledDiv>
  );
};
