import React from "react";
import { Popover } from "@mui/material";
import { ReactComponent as Waffle } from "../../assets/images/waffle.svg";
import { NavIconContainer, NavIcon } from "../lib/navicon";
import WaffleMenu from "./WaffleMenu";
import { SubMenuDialog } from "./SubMenu/SubMenuDialog";
import { AppNavItem } from "./WaffleMenu";
import {
  getAvailableAppsForWaffle,
  getAvailableDataForWaffle,
} from "./ConvertEntitlements";
import { EntitlementContext } from "../EntitlementContext";
import { AuthCookieContext } from "../AuthCookieContext";

export const WaffleWrapper = () => {
  const entitlementContext = React.useContext(EntitlementContext);
  const authCookieContext = React.useContext(AuthCookieContext);
  const [availableApps, setAvailableApps] = React.useState<AppNavItem[]>([]);
  const [availableData, setAvailableData] = React.useState<AppNavItem[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [showSubMenuDialog, setShowSubMenuDialog] = React.useState(false);
  const [selectedAppNavItem, setSelectedAppNavItem] =
    React.useState<AppNavItem | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const baseUrl = authCookieContext?.homeuri
    ? authCookieContext.homeuri
    : process.env.REACT_APP_RL_LAUNCH_URL;

  const openNavDialog = (appNavItem: AppNavItem) => {
    setSelectedAppNavItem(appNavItem);
    setShowSubMenuDialog(true);
  };

  const navigateToLaunchApp = (appNavItem: AppNavItem) => {
    if (appNavItem.url) {
      window.location.href = appNavItem.url!;
    } else if (appNavItem.applicationId && appNavItem.taskId) {
      window.location.href = `${baseUrl}launch?task=${
        appNavItem.taskId
      }&applicationId=${appNavItem.applicationId}${
        appNavItem.deepLinkId ? `&deepLinkId=${appNavItem.deepLinkId}` : ""
      }`;
    }
  };

  React.useEffect(() => {
    const waffleApps = getAvailableAppsForWaffle(
      false,
      entitlementContext?.entitlements
    );
    setAvailableApps(waffleApps);
    const waffleData = getAvailableDataForWaffle(
      false,
      entitlementContext?.entitlements
    );
    setAvailableData(waffleData);
  }, [entitlementContext?.entitlements]);

  return (
    <>
      <NavIconContainer title="App Launcher" onClick={handleClick}>
        <NavIcon>
          <Waffle />
        </NavIcon>
      </NavIconContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 60,
          horizontal: -245,
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px",
          },
        }}
      >
        <WaffleMenu
          availableApps={availableApps}
          availableData={availableData}
          openNavDialog={openNavDialog}
          navigateToLaunchApp={navigateToLaunchApp}
        />
      </Popover>
      {showSubMenuDialog && selectedAppNavItem ? (
        <SubMenuDialog
          showSubMenuDialog={showSubMenuDialog}
          setShowSubMenuDialog={setShowSubMenuDialog}
          closeSubMenuDialog={() => setShowSubMenuDialog(false)}
          appNavItem={selectedAppNavItem}
          navigateToLaunchApp={navigateToLaunchApp}
        />
      ) : null}
    </>
  );
};
